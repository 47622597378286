"use client";
import React from "react";
import { CheckCircle, Users, BookOpen, ArrowRight } from "lucide-react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";

// Framer Motion functionality
const motion = (Component) => {
  return React.forwardRef(({ animate, initial, transition, ...props }, ref) => {
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
      setIsVisible(true);
    }, []);

    const style = {
      ...props.style,
      transition: `transform ${transition?.duration || 0.3}s, opacity ${
        transition?.duration || 0.3
      }s`,
      opacity: isVisible ? animate?.opacity || 1 : initial?.opacity || 0,
      transform: `translateY(${
        isVisible ? animate?.y || 0 : initial?.y || 20
      }px)`,
    };

    return <Component {...props} style={style} ref={ref} />;
  });
};

// Card components
const Card = React.forwardRef(({ className = "", children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={`rounded-lg border border-gray-200 bg-white shadow-sm ${className}`}
      {...props}
    >
      {children}
    </div>
  );
});
Card.displayName = "Card";

const CardContent = ({ className = "", children, ...props }) => {
  return (
    <div className={`p-6 ${className}`} {...props}>
      {children}
    </div>
  );
};

// Button component
const Button = React.forwardRef(
  (
    {
      className = "",
      size = "default",
      variant = "default",
      children,
      ...props
    },
    ref
  ) => {
    const sizeClasses = {
      default: "h-10 px-4 py-2",
      sm: "h-9 px-3",
      lg: "h-11 px-8",
    };

    const variantClasses = {
      default:
        "bg-blue-600 text-white hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
      outline:
        "border border-gray-300 bg-transparent hover:bg-gray-50 text-gray-700",
      ghost: "hover:bg-gray-100 text-gray-700",
    };

    return (
      <button
        ref={ref}
        className={`
        inline-flex items-center justify-center
        rounded-md
        text-sm font-medium
        transition-colors
        focus:outline-none
        disabled:pointer-events-none
        disabled:opacity-50
        ${sizeClasses[size]}
        ${variantClasses[variant]}
        ${className}
      `}
        {...props}
      >
        {children}
      </button>
    );
  }
);
Button.displayName = "Button";

// Modified AboutUs component using custom components
const AboutUs = () => {
  const values = [
    {
      icon: <CheckCircle className="text-blue-600" size={32} />,
      title: "Excellence",
      description:
        "We strive for excellence in everything we do, setting the highest standards in training and consulting.",
    },
    {
      icon: <Users className="text-blue-600" size={32} />,
      title: "Collaboration",
      description:
        "Working together with our clients to achieve exceptional results through shared knowledge and expertise.",
    },
    {
      icon: <BookOpen className="text-blue-600" size={32} />,
      title: "Innovation",
      description:
        "Constantly evolving our methods and embracing new technologies to deliver cutting-edge solutions.",
    },
  ];

  const MotionDiv = motion("div");

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <NavBar />
      <main className="flex-grow">
        {/* Hero Section */}
        <div className="bg-gradient-to-b from-blue-50 to-white py-20">
          <div className="container mx-auto px-4">
            <MotionDiv
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="max-w-3xl mx-auto text-center space-y-6"
            >
              <h1 className="text-5xl font-bold text-gray-900 mb-6 leading-tight">
                About Genvorix
              </h1>
              <p className="text-xl text-gray-600 leading-relaxed">
                We're dedicated to helping businesses achieve excellence through
                expert training and strategic consulting. Our innovative
                approach transforms organizations for the digital age.
              </p>
              <Button size="lg" className="mt-8">
                Learn More <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </MotionDiv>
          </div>
        </div>

        {/* Values Section */}
        <div className="container mx-auto px-4 py-24">
          <h2 className="text-3xl font-bold text-center mb-16">
            Our Core Values
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {values.map((value, index) => (
              <MotionDiv
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
              >
                <Card className="hover:shadow-lg transition-shadow duration-300">
                  <CardContent className="pt-6">
                    <div className="text-center space-y-4">
                      <div className="bg-blue-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6 transform hover:scale-110 transition-transform duration-200">
                        {value.icon}
                      </div>
                      <h3 className="text-xl font-bold text-gray-900">
                        {value.title}
                      </h3>
                      <p className="text-gray-600 leading-relaxed">
                        {value.description}
                      </p>
                    </div>
                  </CardContent>
                </Card>
              </MotionDiv>
            ))}
          </div>
        </div>

        {/* Team Section */}
        {/* <div className="bg-white py-24">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-16 text-center">
              Our Leadership Team
            </h2>
            <MotionDiv
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <Card className="max-w-sm mx-auto hover:shadow-xl transition-shadow duration-300">
                <CardContent className="pt-6">
                  <div className="text-center space-y-4">
                    <div className="relative w-40 h-40 mx-auto mb-6">
                      <div className="absolute inset-0 bg-blue-100 rounded-full transform -rotate-6"></div>
                      <img
                        src="/images/talha.png"
                        alt="Mentor"
                        className="relative rounded-full w-full h-full object-cover border-4 border-white shadow-lg"
                      />
                    </div>
                    <h3 className="text-2xl font-bold text-gray-900">
                      Talha Zaheer
                    </h3>
                    <p className="text-gray-600">Mentor</p>
                    <p className="text-gray-600 mt-4 leading-relaxed">
                      Visionary leader with 8+ years of experience in business
                      transformation and strategic consulting.
                    </p>
                    <div className="flex justify-center space-x-4 mt-6">
                      <Button variant="outline" size="sm">
                        View Profile
                      </Button>
                      <Button variant="outline" size="sm">
                        Connect
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </MotionDiv>
          </div>
        </div> */}
      </main>
      <Footer />
    </div>
  );
};

export default AboutUs;
